import {NavLink} from "react-router-dom";
import ReactCountryFlag from "react-country-flag"
import {useEffect, useState} from "react";
import countries from "i18n-iso-countries";
import Forms from "./Forms";
import Cookies from "universal-cookie";
import LoginModal from "./LoginModal";

const getMonthName = (day) => {
    switch (day) {
        case '01' :
            return ' محرم ';
        case '02' :
            return ' صفر ';
        case '03' :
            return ' ربيع الاول ';
        case '04' :
            return ' ربيع الثاني ';
        case '05' :
            return ' جمادى الاول ';
        case '06' :
            return ' جمادى الثانية ';
        case '07' :
            return ' رجب ';
        case '08' :
            return ' شعبان ';
        case '09' :
            return ' رمضان ';
        case '10' :
            return ' شوال ';
        case '11' :
            return ' ذوالقعدة ';
        case '12' :
            return ' ذوالحجة ';
    }
}

const Question = (props) => {
    const [getQuestion, setQuestion] = useState([]);
    const [showLogin, setShowLogin] = useState(false);
    const [showLogin1, setShowLogin1] = useState(false);

    const [emailIsLogin, setEmailIsLogin] = useState(false);
    const [passIsLogin, setPassIsLogin] = useState(false);

    useEffect(() => {
        const cookies = new Cookies();
        // setSuccessMessage("تم تسجيل الدخول بنجاح");
        // let code = email.value + "m/!@m" + pass;
        if ((cookies.get('myCat') != undefined)) {
            let login = atob(cookies.get('myCat'));
            let arr = login.split("m/!@m");
            setEmailIsLogin(arr[0]);
            setPassIsLogin(arr[1]);
        }

    }, [emailIsLogin]);

    return (
        <div className="left-logo-back">
            {showLogin ?
                <LoginModal show={showLogin} close={() => setShowLogin(false)}/>
                : ""}
            <div className="aq-container pt-4">
                <div className="title-container mt-5 pe-5 pb-4">
                    <NavLink className="d-block p-0" to="/faq" title="الأسئلة والأجوبة العقائدية">
                        <h2>
                            الأسئلة والأجوبة العقائدية
                        </h2>
                    </NavLink>
                    <p>
                        موسوعة معرفية شاملة؛ تحتوي على آلاف الأسئلة العقائدية مع أجوبتها، بترتيب موضوعي الفبائي
                    </p>
                </div>
                <NavLink onClick={() => {
                    if (emailIsLogin.length > 0) {
                        setShowLogin1(true)
                    } else {
                        setShowLogin(true)
                    }
                }} title="أرسل سوالاً للمركز" className="red-btn hidden-desktop">
                    <svg className="ms-1" width="24" height="24" viewBox="0 0 24 24" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                        <circle cx="12" cy="12" r="9" stroke="white" strokeWidth="1.5"/>
                        <ellipse cx="12" cy="17.999" rx="0.5" ry="0.5" fill="white" stroke="white"/>
                        <path
                            d="M12 15.9989V14.58C12 13.6358 12.6042 12.7975 13.5 12.4989V12.4989C14.3958 12.2003 15 11.362 15 10.4178V9.9046C15 8.29982 13.6991 6.9989 12.0943 6.9989H12C10.3431 6.9989 9 8.34205 9 9.9989V9.9989"
                            stroke="white" strokeWidth="1.5"/>
                    </svg>
                </NavLink>
            </div>
            <div className="people-world-container">
                <div className="container d-flex justify-content-between align-items-center py-5">
                    <div className="question-container d-flex justify-content-end align-items-center">
                        <svg className="ms-sm-2 ms-0" width="57" height="58" viewBox="0 0 57 58" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <circle cx="28.5" cy="29" r="28.5" fill="#990000"/>
                            <circle cx="28.4814" cy="46.5869" r="1.41304" fill="#F3F3F3" stroke="#F3F3F3"
                                    strokeWidth="2.82609"/>
                            <path
                                d="M28.4783 38.9348V34.925C28.4783 32.2565 30.1858 29.8873 32.7174 29.0435V29.0435C35.249 28.1996 36.9565 25.8305 36.9565 23.162V21.7117C36.9565 17.1765 33.28 13.5 28.7448 13.5H28.4783C23.7958 13.5 20 17.2958 20 21.9783V21.9783"
                                stroke="#F3F3F3" strokeWidth="2.82609"/>
                        </svg>
                        <div className="question-container-data">
                            <p className="m-0"> إلى كلّ الأحرار في العالم </p>
                            <small className="d-block w-75"> إن كان عندك سؤال أو استفسار عن عقائد مذهب
                                أهل البيت عليهم السلام يمكنك إرساله هنا وستجد الإجابة الوافية إن شاء الله. </small>
                        </div>
                    </div>
                    <button onClick={() => {
                        if (emailIsLogin.length > 0) {
                            setShowLogin1(true)
                        } else {
                            setShowLogin(true)
                        }
                    }} type="click" title="أرسل سوالاً للمركز" className="red-btn mr-80 hidden-phone">
                        <svg className="ms-1" width="24" height="24" viewBox="0 0 24 24" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <circle cx="12" cy="12" r="9" stroke="white" strokeWidth="1.5"/>
                            <ellipse cx="12" cy="17.999" rx="0.5" ry="0.5" fill="white" stroke="white"/>
                            <path
                                d="M12 15.9989V14.58C12 13.6358 12.6042 12.7975 13.5 12.4989V12.4989C14.3958 12.2003 15 11.362 15 10.4178V9.9046C15 8.29982 13.6991 6.9989 12.0943 6.9989H12C10.3431 6.9989 9 8.34205 9 9.9989V9.9989"
                                stroke="white" strokeWidth="1.5"/>
                        </svg>
                        أرسل سوالاً للمركز
                    </button>

                </div>
            </div>
            {showLogin1 ?
                <Forms show={showLogin1} page={1} close={() => setShowLogin1(false)}/>
                : ""}
            <div className="question-answer-container py-5 position-relative">
                <div className="aq-container left-line"></div>
                {props.data.length > 0 ?

                    props.data.map(question =>
                        <div className="container d-flex justify-content-between align-items-center gh-padding py-2">
                            <div className="w-100">
                                <div className="card-question">
                                    <div className="d-flex justify-content-between">
                                        <div className="right">
                                            <ul className="personal-information">
                                                <li>
                                                    <svg className="mb-1 hidden-phone" width="21" height="21"
                                                         viewBox="0 0 21 21" fill="none"
                                                         xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M17.2712 17.5383C16.8914 16.4751 16.0544 15.5357 14.8901 14.8656C13.7258 14.1956 12.2993 13.8324 10.8317 13.8324C9.36415 13.8324 7.93759 14.1956 6.7733 14.8656C5.609 15.5357 4.77203 16.4751 4.3922 17.5383"
                                                            stroke="#045E5E" strokeLinecap="round"/>
                                                        <circle cx="10.8324" cy="7.16536" r="3.33333" stroke="#045E5E"
                                                                strokeLinecap="round"/>
                                                    </svg>
                                                    {question.user?.country?.length > 0 ?
                                                        <img className="hidden-desktop"
                                                             src={"https://dashboard.aqaed.net/flags/" + (question.user?.country).toLowerCase() + ".svg"}
                                                             style={{
                                                                 width: '1.4em',
                                                                 height: '1.4em',
                                                             }}
                                                             title={countries.getName(question.user?.country, 'ar')}
                                                             alt={countries.getName(question.user?.country, 'ar')}
                                                        />
                                                        : ""}
                                                    {question.user?.name + " " + question.user?.family}

                                                </li>
                                                <li className="hidden-phone">
                                                    {question.user?.country?.length > 0 ?
                                                        <img
                                                            src={"https://dashboard.aqaed.net/flags/" + (question.user?.country).toLowerCase() + ".svg"}
                                                            style={{
                                                                width: '1.4em',
                                                                height: '1.4em',
                                                            }}
                                                            title={countries.getName(question.user?.country, 'ar')}
                                                            alt={countries.getName(question.user?.country, 'ar')}
                                                        />
                                                        : ""}
                                                    {countries.getName(question.user?.country, 'ar')}
                                                </li>
                                                {question.category != null ?
                                                    <li>
                                                        <NavLink to={`/category-faq/${question.category.id}`}
                                                                 title={question.category.title}>
                                                            <svg className="mb-1" width="20" height="20"
                                                                 viewBox="0 0 20 20" fill="none"
                                                                 xmlns="http://www.w3.org/2000/svg">
                                                                <rect x="4.16699" y="3.3335" width="11.6667"
                                                                      height="14.1667" rx="2" stroke="#045E5E"/>
                                                                <path d="M7.5 7.5H12.5" stroke="#045E5E"
                                                                      strokeLinecap="round"/>
                                                                <path d="M7.5 10.8334H12.5" stroke="#045E5E"
                                                                      strokeLinecap="round"/>
                                                                <path d="M7.5 14.1666H10.8333" stroke="#045E5E"
                                                                      strokeLinecap="round"/>
                                                            </svg>
                                                            {question.category.title}
                                                        </NavLink>
                                                    </li> : ""
                                                }

                                                <li title="تعليقاً وتعقيباً">
                                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                                         xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M16.1036 4.81481C16.6654 5.65555 16.6654 6.82594 16.6654 9.16671C16.6654 11.5075 16.6654 12.6779 16.1036 13.5186C15.8604 13.8826 15.5479 14.1951 15.1839 14.4383C14.4495 14.929 13.4634 14.9911 11.6654 14.9989V15L10.7441 16.8427C10.4369 17.4569 9.56045 17.4569 9.25334 16.8427L8.33203 15V14.9989C6.53395 14.9911 5.54792 14.929 4.81346 14.4383C4.4495 14.1951 4.13699 13.8826 3.8938 13.5186C3.33203 12.6779 3.33203 11.5075 3.33203 9.16671C3.33203 6.82594 3.33203 5.65555 3.8938 4.81481C4.13699 4.45084 4.4495 4.13834 4.81346 3.89514C5.65421 3.33337 6.82459 3.33337 9.16536 3.33337H10.832C13.1728 3.33337 14.3432 3.33337 15.1839 3.89514C15.5479 4.13834 15.8604 4.45084 16.1036 4.81481Z"
                                                            stroke="#045E5E" strokeLinecap="round"
                                                            strokeLinejoin="round"/>
                                                        <path d="M7.5 7.5L12.5 7.5" stroke="#045E5E"
                                                              strokeLinecap="round" strokeLinejoin="round"/>
                                                        <path d="M7.5 10.8334H10" stroke="#045E5E" strokeLinecap="round"
                                                              strokeLinejoin="round"/>
                                                    </svg>
                                                    <span className="number">
                                                    {question.comment_count}
                                                </span>

                                                </li>
                                            </ul>
                                            <NavLink to={"/qa/" + question.id} title={question.title}>
                                                <h3>{question.title.length > 120 ?
                                                    `${question.title.substring(0, 120)}...` : question.title
                                                }</h3>
                                            </NavLink>
                                        </div>


                                        <div className="left hidden-phone">
                                            <svg className="mb-1 ms-1" width="19" height="18" viewBox="0 0 19 18"
                                                 fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M15.0417 3H3.95833C3.08388 3 2.375 3.67157 2.375 4.5V15C2.375 15.8284 3.08388 16.5 3.95833 16.5H15.0417C15.9161 16.5 16.625 15.8284 16.625 15V4.5C16.625 3.67157 15.9161 3 15.0417 3Z"
                                                    stroke="#045E5E" strokeLinecap="round" strokeLinejoin="round"/>
                                                <path d="M12.666 1.50012V4.50012" stroke="#045E5E" strokeLinecap="round"
                                                      strokeLinejoin="round"/>
                                                <path d="M6.33398 1.50012V4.50012" stroke="#045E5E"
                                                      strokeLinecap="round" strokeLinejoin="round"/>
                                                <path d="M2.375 7.5H16.625" stroke="#045E5E" strokeLinecap="round"
                                                      strokeLinejoin="round"/>
                                            </svg>

                                            <div className="date">
                                                <span
                                                    className="number"> {question.date_lunar ? question.date_lunar.split("-")[2] : "02"} </span>
                                                {getMonthName(question.date_lunar ? question.date_lunar.split("-")[1] : "02")}
                                                <span
                                                    className="number"> {question.date_lunar ? question.date_lunar.split("-")[0] : "1500"} </span>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                    : ""}


                <div className="more-btn-container d-flex justify-content-center mt-3">
                    <NavLink className="more-btn" to="/faq">
                        المزيد
                    </NavLink>
                </div>

            </div>
        </div>
    );
};

export default Question;