import imageLoading from "../assets/svg/infinit-black.svg";
import {Swiper, SwiperSlide} from "swiper/react";
import {Navigation} from "swiper";
import SwiperCore, {Autoplay} from 'swiper';
import {NavLink} from "react-router-dom";
import image from "../assets/uploads/book.jpg";
import image2 from "../assets/uploads/beaut-mosque-06.jpg";
import imageUser from "../assets/uploads/user.jpg";
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import {useEffect, useState} from 'react';
import ReactCountryFlag from "react-country-flag";
import countries from "i18n-iso-countries";
import img from "../assets/uploads/user.jpg";
import Forms from "./Forms";
import Cookies from "universal-cookie";
import LoginModal from "./LoginModal";
import {useUser} from "../services/UserContext";

const getMonthName = (day) => {
    switch (day) {
        case '01' :
            return ' محرم ';
        case '02' :
            return ' صفر ';
        case '03' :
            return ' ربيع الاول ';
        case '04' :
            return ' ربيع الثاني ';
        case '05' :
            return ' جمادى الاول ';
        case '06' :
            return ' جمادى الثانية ';
        case '07' :
            return ' رجب ';
        case '08' :
            return ' شعبان ';
        case '09' :
            return ' رمضان ';
        case '10' :
            return ' شوال ';
        case '11' :
            return ' ذوالقعدة ';
        case '12' :
            return ' ذوالحجة ';
    }
}
const Guided = (props) => {
    SwiperCore.use([Autoplay]);
    const [getCount, setCount] = useState(1);
    const [getGuided, setGuided] = useState(1);
    const [getArticleMustabasrs, setArticleMustabasrs] = useState(1);
    const [getMoghabelat, setMoghabelat] = useState(1);
    const [getGuidedBooks, setGuidedBooks] = useState([]);
    const [showLogin, setShowLogin] = useState(false);
    const [showLogin1, setShowLogin1] = useState(false);

    const [loading, setLoading] = useState(false);


    const [emailIsLogin, setEmailIsLogin] = useState(false);
    const [passIsLogin, setPassIsLogin] = useState(false);
    const [idIsLogin, setIdIsLogin] = useState(false);
    const [nameIsLogin, setNameIsLogin] = useState(false);
    const [lastNameIsLogin, setLastNameIsLogin] = useState(false);
    const [CountryIsLogin, setCountryIsLogin] = useState(false);
    const [religionIsLogin, setReligionIsLogin] = useState(false);
    const {userData, setLoginData} = useUser();
    useEffect(() => {
        const getUserList = () => {
            var myHeaders = new Headers();
            myHeaders.append("Accept", "application/json");

            var formdata = new FormData();
            formdata.append("email", emailIsLogin);
            formdata.append("password", passIsLogin);

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: formdata,
                redirect: 'follow'
            };
            setNameIsLogin(userData?.name);
            setLastNameIsLogin(userData?.family);
            setCountryIsLogin(userData?.country);
            setReligionIsLogin(userData?.previous_religion);

        };
        const cookies = new Cookies();
        // setSuccessMessage("تم تسجيل الدخول بنجاح");
        // let code = email.value + "m/!@m" + pass;
        if ((cookies.get('myCat') != undefined)) {
            let login = atob(cookies.get('myCat'));
            let arr = login.split("m/!@m");
            setEmailIsLogin(arr[0]);
            setPassIsLogin(arr[1]);
            getUserList();
        }

    }, [emailIsLogin, userData]);


    const handleClick = (id) => {
        setCount(id)
    };


    const fetchData = () => {

        if (getCount == 2) {
            setLoading(true)
            fetch("https://dashboard.aqaed.net/api/v1/getAllBookMustabasrs?limit=10")
                .then(response => {
                    return response.json()
                })
                .then(data => {
                    setGuidedBooks(data.data)
                    setLoading(false)
                });
        } else if (getCount == 3) {
            setLoading(true)
            fetch("https://dashboard.aqaed.net/api/v1/getAllArticleMustabasrs/10")
                .then(response => {
                    return response.json()
                })
                .then(data => {
                    setArticleMustabasrs(data.data)
                    setLoading(false)
                });
        } else if (getCount == 4) {
            setLoading(true)
            fetch("https://dashboard.aqaed.net/api/v1/lastMoghablats/10")
                .then(response => {
                    return response.json()
                })
                .then(data => {
                    setMoghabelat(data.data)
                    setLoading(false)
                });
        }
    }

    useEffect(() => {
        fetchData()
    }, [getCount])
    return (
        <div className="guided-module position-relative">
            {showLogin ?
                <LoginModal show={showLogin} close={() => setShowLogin(false)}/>
                : ""}
            <div className="aq-container pt-4 d-flex justify-content-between align-items-center">
                <div className="title-container mt-5 pe-5 pb-4">
                    <NavLink to="/guided" title="المستبصرون">
                        <h2>
                            المستبصرون
                        </h2>
                    </NavLink>

                    <p>
                        التعرّف على معالِم حركة الاستبصار العالمية، وأسباب اعتناق مذهب أهل البيت(عليهم السلام)
                    </p>
                </div>
            </div>
            <div className="aq-container px-5 pt-4">
                <div className="row">
                    <div className="col-12 new-container-phone hidden-desktop">
                        <div className="menu-tab">
                            <ul className="left-tab">
                                <li className={(getCount == 1) ? "active" : ""} onClick={event => handleClick(1)}>
                                    <svg width="34" height="34" viewBox="0 0 34 34" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="14" cy="14" r="14" fill="#045E5E" fillOpacity="0.95"/>
                                        <path
                                            d="M18.57 18.6687C18.2901 17.8851 17.6732 17.1927 16.815 16.6988C15.9569 16.2049 14.9055 15.9373 13.8238 15.9373C12.7421 15.9373 11.6907 16.2049 10.8326 16.6988C9.97442 17.1927 9.35754 17.8851 9.07758 18.6687"
                                            stroke="#045E5E" strokeWidth="0.731179" strokeLinecap="round"/>
                                        <circle className="active-white" cx="13.824" cy="11.0237" r="2.45682"
                                                stroke="#fff"
                                                strokeWidth="0.731179" strokeLinecap="round"/>
                                    </svg>
                                    <h3>
                                        أحدث قائمة للمستبصرين
                                    </h3>
                                </li>

                                <li className={(getCount == 2) ? "active" : ""} onClick={event => handleClick(2)}>

                                    <svg width="34" height="34" viewBox="0 0 34 34" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="14" cy="14" r="14" fill="#045E5E"/>
                                        <path
                                            d="M9.36765 18.2546H11.9412C13.0782 18.2546 14 19.1763 14 20.3134V13.9654C14 11.9349 14 10.9196 13.4392 10.2363C13.3365 10.1112 13.2218 9.99647 13.0967 9.8938C12.4134 9.33301 11.3982 9.33301 9.36765 9.33301C8.69081 9.33301 8.3524 9.33301 8.12462 9.51994C8.08292 9.55416 8.04468 9.5924 8.01046 9.6341C7.82353 9.86187 7.82353 10.2003 7.82353 10.8771V16.7105C7.82353 17.3873 7.82353 17.7257 8.01046 17.9535C8.04468 17.9952 8.08292 18.0334 8.12462 18.0676C8.3524 18.2546 8.69081 18.2546 9.36765 18.2546Z"
                                            stroke="white" strokeOpacity="0.9" strokeWidth="0.823529"/>
                                        <path
                                            d="M18.6324 18.2546H16.0588C14.9218 18.2546 14 19.1763 14 20.3134V13.9654C14 11.9349 14 10.9196 14.5608 10.2363C14.6635 10.1112 14.7782 9.99647 14.9033 9.8938C15.5866 9.33301 16.6018 9.33301 18.6324 9.33301C19.3092 9.33301 19.6476 9.33301 19.8754 9.51994C19.9171 9.55416 19.9553 9.5924 19.9895 9.6341C20.1765 9.86187 20.1765 10.2003 20.1765 10.8771V16.7105C20.1765 17.3873 20.1765 17.7257 19.9895 17.9535C19.9553 17.9952 19.9171 18.0334 19.8754 18.0676C19.6476 18.2546 19.3092 18.2546 18.6324 18.2546Z"
                                            stroke="white" strokeOpacity="0.9" strokeWidth="0.823529"/>
                                    </svg>
                                    <h3>
                                        مؤلّفات المستبصرين
                                    </h3>
                                </li>

                                <li className={(getCount == 3) ? "active" : ""} onClick={event => handleClick(3)}>
                                    <svg width="34" height="34" viewBox="0 0 34 34" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M14 28C21.732 28 28 21.732 28 14C28 6.26801 21.732 0 14 0C6.26801 0 0 6.26801 0 14C0 21.732 6.26801 28 14 28Z"
                                            fill="#EEEEEE"/>
                                        <path
                                            d="M18.3125 18.6563C18.3125 18.7806 18.2631 18.8998 18.1752 18.9877C18.0873 19.0756 17.9681 19.125 17.8438 19.125H10.3438C10.2194 19.125 10.1002 19.0756 10.0123 18.9877C9.92439 18.8998 9.875 18.7806 9.875 18.6563V8.34375C9.875 8.21943 9.92439 8.1002 10.0123 8.01229C10.1002 7.92439 10.2194 7.875 10.3438 7.875H14.9188H15.0312V9.75C15.0312 10.123 15.1794 10.4806 15.4431 10.7444C15.7069 11.0081 16.0645 11.1563 16.4375 11.1563L17.375 10.2188H16.4375C16.3132 10.2188 16.194 10.1694 16.106 10.0815C16.0181 9.99355 15.9688 9.87432 15.9688 9.75V8.53594L17.5109 10.0781L17.9281 9.66094L18.1719 9.41719L16.2453 7.48594C16.0711 7.31187 15.8642 7.17385 15.6366 7.07974C15.409 6.98564 15.1651 6.93731 14.9188 6.9375H10.3438C9.97079 6.9375 9.6131 7.08566 9.34938 7.34938C9.08566 7.6131 8.9375 7.97079 8.9375 8.34375V18.6563C8.9375 19.0292 9.08566 19.3869 9.34938 19.6506C9.6131 19.9143 9.97079 20.0625 10.3438 20.0625H17.8438C18.2167 20.0625 18.5744 19.9143 18.8381 19.6506C19.1018 19.3869 19.25 19.0292 19.25 18.6563V15.1031L18.3125 16.0406V18.6563Z"
                                            fill="#045E5E"/>
                                        <path
                                            d="M21.5938 9.39354C21.4679 9.24291 21.3106 9.12174 21.1328 9.0386C20.955 8.95545 20.7611 8.91235 20.5648 8.91235C20.3686 8.91235 20.1747 8.95545 19.9969 9.0386C19.8191 9.12174 19.6618 9.24291 19.5359 9.39354L18.5984 10.331L14.6141 14.2967C14.4449 14.4696 14.2984 14.6633 14.1781 14.8732L13.0109 17.2498H11.2812C11.1569 17.2498 11.0377 17.2992 10.9498 17.3871C10.8619 17.475 10.8125 17.5942 10.8125 17.7185C10.8125 17.8429 10.8619 17.9621 10.9498 18.05C11.0377 18.1379 11.1569 18.1873 11.2812 18.1873H13.1562C13.2731 18.1857 13.3851 18.1406 13.4703 18.0607C13.4983 18.0651 13.5267 18.0651 13.5547 18.0607L16.1375 16.781C16.3324 16.6631 16.5119 16.5214 16.6719 16.3592L16.9344 16.1014L20.6562 12.3842L21.5938 11.4467C21.737 11.316 21.8524 11.1577 21.933 10.9813C22.0135 10.8048 22.0576 10.614 22.0625 10.4201C22.0565 10.2264 22.012 10.0359 21.9315 9.85963C21.851 9.68336 21.7362 9.52492 21.5938 9.39354ZM15.8141 15.8435L15.6875 15.9326L14.375 16.5795L14.9984 15.2998C15.0625 15.199 15.1363 15.1048 15.2188 15.0185L15.5047 15.3045L15.9359 15.7404L15.8141 15.8435ZM16.6063 15.0795L16.2406 14.7185L15.875 14.3482L18.9219 11.3014L19.6531 12.0326L16.6063 15.0795ZM20.9141 10.7717L20.3141 11.3904L19.5828 10.6545L20.1875 10.0592C20.2801 9.94722 20.4099 9.87234 20.5531 9.84823C20.6961 9.87313 20.8256 9.94786 20.9187 10.0592C21.0291 10.1531 21.1037 10.2822 21.1297 10.4248C21.1013 10.5676 21.0253 10.6965 20.9141 10.7904V10.7717Z"
                                            fill="#045E5E"/>
                                    </svg>

                                    <h3>
                                        مساهمات المستبصرين
                                    </h3>
                                </li>
                                <li className={(getCount == 4) ? "active" : ""} onClick={event => handleClick(4)}>

                                    <svg width="34" height="34" viewBox="0 0 34 34" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="14" cy="14" r="14" fill="#045E5E"/>
                                        <g clipPath="url(#clip0_845_27269)">
                                            <path d="M20.8294 11.4949L16.7461 14.4115L20.8294 17.3282V11.4949Z"
                                                  stroke="white" strokeOpacity="0.9" strokeWidth="0.823529"
                                                  strokeLinecap="round" strokeLinejoin="round"/>
                                            <path
                                                d="M15.5794 10.3284H9.16276C8.51843 10.3284 7.99609 10.8507 7.99609 11.495V17.3284C7.99609 17.9727 8.51843 18.495 9.16276 18.495H15.5794C16.2238 18.495 16.7461 17.9727 16.7461 17.3284V11.495C16.7461 10.8507 16.2238 10.3284 15.5794 10.3284Z"
                                                stroke="white" strokeOpacity="0.9" strokeWidth="0.823529"
                                                strokeLinecap="round" strokeLinejoin="round"/>
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_845_27269">
                                                <rect width="14" height="14" fill="white"
                                                      transform="translate(7.41211 7.41187)"/>
                                            </clipPath>
                                        </defs>
                                    </svg>
                                    <h3>
                                        مقابلات المستبصرين
                                    </h3>
                                </li>
                                <li className={(getCount == 5) ? "active" : ""} onClick={event => handleClick(5)}>

                                    <svg width="34" height="34" viewBox="0 0 34 34" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="14" cy="14" r="14" fill="#045E5E" fillOpacity="0.95"/>
                                        <path
                                            d="M18.57 18.6687C18.2901 17.8851 17.6732 17.1927 16.815 16.6988C15.9569 16.2049 14.9055 15.9373 13.8238 15.9373C12.7421 15.9373 11.6907 16.2049 10.8326 16.6988C9.97442 17.1927 9.35754 17.8851 9.07758 18.6687"
                                            stroke="#045E5E" strokeWidth="0.731179" strokeLinecap="round"/>
                                        <circle className="active-white" cx="13.824" cy="11.0237" r="2.45682"
                                                stroke="#045E5E"
                                                strokeWidth="0.731179" strokeLinecap="round"/>
                                    </svg>
                                    <h3>
                                        إعلان الاستبصار
                                    </h3>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-4 hidden-phone">
                        <ul className="left-tab">
                            <li className={(getCount == 1) ? "active" : ""} onClick={event => handleClick(1)}>
                                <svg width="34" height="34" viewBox="0 0 34 34" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="14" cy="14" r="14" fill="#045E5E" fillOpacity="0.95"/>
                                    <path
                                        d="M18.57 18.6687C18.2901 17.8851 17.6732 17.1927 16.815 16.6988C15.9569 16.2049 14.9055 15.9373 13.8238 15.9373C12.7421 15.9373 11.6907 16.2049 10.8326 16.6988C9.97442 17.1927 9.35754 17.8851 9.07758 18.6687"
                                        stroke="#045E5E" strokeWidth="0.731179" strokeLinecap="round"/>
                                    <circle className="active-white" cx="13.824" cy="11.0237" r="2.45682" stroke="#fff"
                                            strokeWidth="0.731179" strokeLinecap="round"/>
                                </svg>
                                <h3>
                                    أحدث قائمة للمستبصرين
                                </h3>
                            </li>
                            <li className={(getCount == 2) ? "active" : ""} onClick={event => handleClick(2)}>

                                <svg width="34" height="34" viewBox="0 0 34 34" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="14" cy="14" r="14" fill="#045E5E"/>
                                    <path
                                        d="M9.36765 18.2546H11.9412C13.0782 18.2546 14 19.1763 14 20.3134V13.9654C14 11.9349 14 10.9196 13.4392 10.2363C13.3365 10.1112 13.2218 9.99647 13.0967 9.8938C12.4134 9.33301 11.3982 9.33301 9.36765 9.33301C8.69081 9.33301 8.3524 9.33301 8.12462 9.51994C8.08292 9.55416 8.04468 9.5924 8.01046 9.6341C7.82353 9.86187 7.82353 10.2003 7.82353 10.8771V16.7105C7.82353 17.3873 7.82353 17.7257 8.01046 17.9535C8.04468 17.9952 8.08292 18.0334 8.12462 18.0676C8.3524 18.2546 8.69081 18.2546 9.36765 18.2546Z"
                                        stroke="white" strokeOpacity="0.9" strokeWidth="0.823529"/>
                                    <path
                                        d="M18.6324 18.2546H16.0588C14.9218 18.2546 14 19.1763 14 20.3134V13.9654C14 11.9349 14 10.9196 14.5608 10.2363C14.6635 10.1112 14.7782 9.99647 14.9033 9.8938C15.5866 9.33301 16.6018 9.33301 18.6324 9.33301C19.3092 9.33301 19.6476 9.33301 19.8754 9.51994C19.9171 9.55416 19.9553 9.5924 19.9895 9.6341C20.1765 9.86187 20.1765 10.2003 20.1765 10.8771V16.7105C20.1765 17.3873 20.1765 17.7257 19.9895 17.9535C19.9553 17.9952 19.9171 18.0334 19.8754 18.0676C19.6476 18.2546 19.3092 18.2546 18.6324 18.2546Z"
                                        stroke="white" strokeOpacity="0.9" strokeWidth="0.823529"/>
                                </svg>
                                <h3>
                                    مؤلّفات المستبصرين
                                </h3>
                            </li>
                            <li className={(getCount == 3) ? "active" : ""} onClick={event => handleClick(3)}>
                                <svg width="34" height="34" viewBox="0 0 34 34" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M14 28C21.732 28 28 21.732 28 14C28 6.26801 21.732 0 14 0C6.26801 0 0 6.26801 0 14C0 21.732 6.26801 28 14 28Z"
                                        fill="#EEEEEE"/>
                                    <path
                                        d="M18.3125 18.6563C18.3125 18.7806 18.2631 18.8998 18.1752 18.9877C18.0873 19.0756 17.9681 19.125 17.8438 19.125H10.3438C10.2194 19.125 10.1002 19.0756 10.0123 18.9877C9.92439 18.8998 9.875 18.7806 9.875 18.6563V8.34375C9.875 8.21943 9.92439 8.1002 10.0123 8.01229C10.1002 7.92439 10.2194 7.875 10.3438 7.875H14.9188H15.0312V9.75C15.0312 10.123 15.1794 10.4806 15.4431 10.7444C15.7069 11.0081 16.0645 11.1563 16.4375 11.1563L17.375 10.2188H16.4375C16.3132 10.2188 16.194 10.1694 16.106 10.0815C16.0181 9.99355 15.9688 9.87432 15.9688 9.75V8.53594L17.5109 10.0781L17.9281 9.66094L18.1719 9.41719L16.2453 7.48594C16.0711 7.31187 15.8642 7.17385 15.6366 7.07974C15.409 6.98564 15.1651 6.93731 14.9188 6.9375H10.3438C9.97079 6.9375 9.6131 7.08566 9.34938 7.34938C9.08566 7.6131 8.9375 7.97079 8.9375 8.34375V18.6563C8.9375 19.0292 9.08566 19.3869 9.34938 19.6506C9.6131 19.9143 9.97079 20.0625 10.3438 20.0625H17.8438C18.2167 20.0625 18.5744 19.9143 18.8381 19.6506C19.1018 19.3869 19.25 19.0292 19.25 18.6563V15.1031L18.3125 16.0406V18.6563Z"
                                        fill="#045E5E"/>
                                    <path
                                        d="M21.5938 9.39354C21.4679 9.24291 21.3106 9.12174 21.1328 9.0386C20.955 8.95545 20.7611 8.91235 20.5648 8.91235C20.3686 8.91235 20.1747 8.95545 19.9969 9.0386C19.8191 9.12174 19.6618 9.24291 19.5359 9.39354L18.5984 10.331L14.6141 14.2967C14.4449 14.4696 14.2984 14.6633 14.1781 14.8732L13.0109 17.2498H11.2812C11.1569 17.2498 11.0377 17.2992 10.9498 17.3871C10.8619 17.475 10.8125 17.5942 10.8125 17.7185C10.8125 17.8429 10.8619 17.9621 10.9498 18.05C11.0377 18.1379 11.1569 18.1873 11.2812 18.1873H13.1562C13.2731 18.1857 13.3851 18.1406 13.4703 18.0607C13.4983 18.0651 13.5267 18.0651 13.5547 18.0607L16.1375 16.781C16.3324 16.6631 16.5119 16.5214 16.6719 16.3592L16.9344 16.1014L20.6562 12.3842L21.5938 11.4467C21.737 11.316 21.8524 11.1577 21.933 10.9813C22.0135 10.8048 22.0576 10.614 22.0625 10.4201C22.0565 10.2264 22.012 10.0359 21.9315 9.85963C21.851 9.68336 21.7362 9.52492 21.5938 9.39354ZM15.8141 15.8435L15.6875 15.9326L14.375 16.5795L14.9984 15.2998C15.0625 15.199 15.1363 15.1048 15.2188 15.0185L15.5047 15.3045L15.9359 15.7404L15.8141 15.8435ZM16.6063 15.0795L16.2406 14.7185L15.875 14.3482L18.9219 11.3014L19.6531 12.0326L16.6063 15.0795ZM20.9141 10.7717L20.3141 11.3904L19.5828 10.6545L20.1875 10.0592C20.2801 9.94722 20.4099 9.87234 20.5531 9.84823C20.6961 9.87313 20.8256 9.94786 20.9187 10.0592C21.0291 10.1531 21.1037 10.2822 21.1297 10.4248C21.1013 10.5676 21.0253 10.6965 20.9141 10.7904V10.7717Z"
                                        fill="#045E5E"/>
                                </svg>

                                <h3>
                                    مساهمات المستبصرين
                                </h3>
                            </li>
                            <li className={(getCount == 4) ? "active" : ""} onClick={event => handleClick(4)}>

                                <svg width="34" height="34" viewBox="0 0 34 34" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="14" cy="14" r="14" fill="#045E5E"/>
                                    <g clipPath="url(#clip0_845_27269)">
                                        <path d="M20.8294 11.4949L16.7461 14.4115L20.8294 17.3282V11.4949Z"
                                              stroke="white" strokeOpacity="0.9" strokeWidth="0.823529"
                                              strokeLinecap="round" strokeLinejoin="round"/>
                                        <path
                                            d="M15.5794 10.3284H9.16276C8.51843 10.3284 7.99609 10.8507 7.99609 11.495V17.3284C7.99609 17.9727 8.51843 18.495 9.16276 18.495H15.5794C16.2238 18.495 16.7461 17.9727 16.7461 17.3284V11.495C16.7461 10.8507 16.2238 10.3284 15.5794 10.3284Z"
                                            stroke="white" strokeOpacity="0.9" strokeWidth="0.823529"
                                            strokeLinecap="round" strokeLinejoin="round"/>
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_845_27269">
                                            <rect width="14" height="14" fill="white"
                                                  transform="translate(7.41211 7.41187)"/>
                                        </clipPath>
                                    </defs>
                                </svg>
                                <h3>
                                    مقابلات المستبصرين
                                </h3>
                            </li>
                            <li className={(getCount == 5) ? "active" : ""} onClick={event => handleClick(5)}>

                                <svg width="34" height="34" viewBox="0 0 34 34" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="14" cy="14" r="14" fill="#045E5E" fillOpacity="0.95"/>
                                    <path
                                        d="M18.57 18.6687C18.2901 17.8851 17.6732 17.1927 16.815 16.6988C15.9569 16.2049 14.9055 15.9373 13.8238 15.9373C12.7421 15.9373 11.6907 16.2049 10.8326 16.6988C9.97442 17.1927 9.35754 17.8851 9.07758 18.6687"
                                        stroke="#045E5E" strokeWidth="0.731179" strokeLinecap="round"/>
                                    <circle className="active-white" cx="13.824" cy="11.0237" r="2.45682"
                                            stroke="#045E5E"
                                            strokeWidth="0.731179" strokeLinecap="round"/>
                                </svg>
                                <h3>
                                    إعلان الاستبصار
                                </h3>
                            </li>
                        </ul>
                    </div>
                    <div className="col-8 user-slider p-5 pt-0 ps-0">
                        {!loading ?
                            <>

                                {props.data.length > 0 ?
                                    <Swiper
                                        slidesPerView={'auto'}
                                        spaceBetween={40}
                                        loop={true}
                                        autoplay={{
                                            delay: 2000,
                                            disableOnInteraction: true,
                                            pauseOnMouseEnter: true,
                                        }}
                                        grabCursor={true}
                                        dir="rtl"
                                        breakpoints={{
                                            0: {
                                                spaceBetween: 20
                                            },
                                            900: {
                                                spaceBetween: 40
                                            }
                                        }}
                                        modules={[Navigation]}
                                        navigation={true}
                                        className={(getCount == 1) ? "mySwiper visible show " : "mySwiper  visually-hidden fade"}>

                                        {props.data.map(Guided => (
                                            <SwiperSlide>
                                                <NavLink className="user-guide" to={"Guided/" + Guided.id}>
                                                    <div
                                                        className="header-user-image-container d-flex justify-content-center">
                                                        <img className="header-user-image" src={Guided.image}
                                                             alt={Guided.name} title={Guided.name}/>
                                                    </div>
                                                    <div className="user-desc-container">
                                                        <div className="user-name pt-sm-4 pt-2">
                                                            <svg width="19" height="18" viewBox="0 0 19 18" fill="none"
                                                                 xmlns="http://www.w3.org/2000/svg">
                                                                <path
                                                                    d="M14.8898 15.0655C14.554 14.1254 13.814 13.2948 12.7845 12.7024C11.7551 12.1099 10.4938 11.7888 9.19623 11.7888C7.89865 11.7888 6.63734 12.1099 5.6079 12.7024C4.57847 13.2948 3.83844 14.1254 3.50261 15.0655"
                                                                    stroke="#989898" strokeLinecap="round"/>
                                                                <circle cx="9.19821" cy="5.8945" r="2.94723"
                                                                        stroke="#989898"
                                                                        strokeLinecap="round"/>
                                                            </svg>
                                                            {Guided.name}
                                                        </div>
                                                        <div
                                                            className="d-flex justify-content-center  user-desc-container-2">
                                                            <div className="c-name">
                                                                {Guided.country?.length ?
                                                                    <img
                                                                        src={"https://dashboard.aqaed.net/flags/" + (Guided.country).toLowerCase() + ".svg"}
                                                                        style={{
                                                                            width: '1.4em',
                                                                            height: '1.4em',
                                                                        }}
                                                                        title={Guided.country}
                                                                        alt={Guided.country}
                                                                    />
                                                                    : ""}
                                                                {countries.getName(Guided.country, 'ar')}
                                                            </div>
                                                            <div className="last-Religion" title="المذهب السابق">
                                                                {Guided.previous_religion?.length > 0 ?
                                                                    <svg width="18" height="18" viewBox="0 0 18 18"
                                                                         fill="none"
                                                                         xmlns="http://www.w3.org/2000/svg">
                                                                        <g clipPath="url(#clip0_1718_10792)">
                                                                            <path
                                                                                d="M14.2032 14.339H5.30278C4.71444 13.2962 4.6156 12.0667 5.05333 10.9632C5.81111 9.0602 7.53377 7.5526 9.75063 7.00952C11.9675 7.5526 13.6902 9.0602 14.4479 10.9632C14.8904 12.0667 14.7915 13.2962 14.2032 14.339Z"
                                                                                stroke="#989898" strokeWidth="0.9"
                                                                                strokeMiterlimit="10"
                                                                                strokeLinecap="round"
                                                                                strokeLinejoin="round"/>
                                                                            <path
                                                                                d="M5.99427 4.58516H3.56561C3.40558 4.29842 3.37734 3.96388 3.49971 3.6641C3.70681 3.14274 4.17748 2.73434 4.78465 2.58228C5.39181 2.72999 5.86248 3.14274 6.06958 3.6641C6.18254 3.96388 6.1543 4.30276 5.99427 4.58516Z"
                                                                                stroke="#989898" strokeWidth="0.9"
                                                                                strokeMiterlimit="10"
                                                                                strokeLinecap="round"
                                                                                strokeLinejoin="round"/>
                                                                            <path
                                                                                d="M5.05389 10.9632C5.2704 10.4157 5.57163 9.90307 5.93404 9.43385V4.58521H3.62305V14.339H5.29864C4.7103 13.2962 4.61146 12.0667 5.05389 10.9632Z"
                                                                                stroke="#989898" strokeWidth="0.9"
                                                                                strokeMiterlimit="10"
                                                                                strokeLinecap="round"
                                                                                strokeLinejoin="round"/>
                                                                            <path d="M9.75098 7.00958V5.82349"
                                                                                  stroke="#989898"
                                                                                  strokeMiterlimit="10"
                                                                                  strokeLinecap="round"
                                                                                  strokeLinejoin="round"/>
                                                                            <path d="M4.78027 2.58238V1.40063"
                                                                                  stroke="#989898"
                                                                                  strokeWidth="0.9"
                                                                                  strokeMiterlimit="10"
                                                                                  strokeLinecap="round"
                                                                                  strokeLinejoin="round"/>
                                                                            <path
                                                                                d="M15.399 14.3391H2.54492V16.2855H15.399V14.3391Z"
                                                                                stroke="#989898" strokeWidth="0.9"
                                                                                strokeMiterlimit="10"
                                                                                strokeLinecap="round"
                                                                                strokeLinejoin="round"/>
                                                                        </g>
                                                                        <defs>
                                                                            <clipPath id="clip0_1718_10792">
                                                                                <rect width="17" height="17"
                                                                                      fill="white"
                                                                                      transform="translate(0.381836 0.41333)"/>
                                                                            </clipPath>
                                                                        </defs>
                                                                    </svg>
                                                                    : ""}
                                                                {Guided.previous_religion}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </NavLink>
                                            </SwiperSlide>
                                        ))}
                                        <div className="more-btn-container d-flex justify-content-end mt--52">
                                            <NavLink className="more-btn" to="/guided">
                                                المزيد
                                            </NavLink>
                                        </div>
                                    </Swiper>
                                    : ""}
                                {/*tab2*/}
                                <div
                                    className={(getCount == 2) ? "books-module books-module-2 pb-0 position-relative visible show" :
                                        "books-module books-module-2 pb-0 position-relative visually-hidden fade"}>
                                    <div className="aq-container">
                                        <div className="books-module pb-0 position-relative">
                                            <div
                                                className="aq-container book-container book-container-green-nav nav-right phone-m-0 change-to-book">

                                                <Swiper
                                                    slidesPerView={'auto'}
                                                    spaceBetween={20}
                                                    grabCursor={true}
                                                    loop={true}
                                                    autoplay={{
                                                        delay: 2000,
                                                        disableOnInteraction: true,
                                                        pauseOnMouseEnter: true,
                                                    }}
                                                    dir="rtl"
                                                    breakpoints={{
                                                        0: {
                                                            spaceBetween: 15
                                                        },
                                                        900: {
                                                            spaceBetween: 20
                                                        }
                                                    }}
                                                    modules={[Navigation]}
                                                    navigation={true}
                                                    className="mySwiper">

                                                    {getGuidedBooks.length > 0 ?
                                                        getGuidedBooks.map(guidedBooks =>
                                                            <SwiperSlide>
                                                                <div className="book-image-container">
                                                                    <NavLink to={"books/" + guidedBooks.id}
                                                                             title={guidedBooks.title}>
                                                                        <img className="books-image"
                                                                             src={guidedBooks.image}
                                                                             alt={guidedBooks.title}
                                                                             title={guidedBooks.title}/>
                                                                    </NavLink>
                                                                </div>
                                                                <div className="book-data-container ">
                                                                    <NavLink to={"books/" + guidedBooks.id}
                                                                             title={guidedBooks.title}>
                                                                        <h3>
                                                                            {guidedBooks.title?.length > 40 ?
                                                                                `${guidedBooks.title.substring(0, 40)}...` : guidedBooks.title
                                                                            }
                                                                        </h3>
                                                                    </NavLink>
                                                                    <NavLink
                                                                        to={`/books?tab=all&authorId=${guidedBooks.author[0].id}&authorName=${guidedBooks.author[0].title}`}
                                                                        reloadDocument
                                                                        title={guidedBooks.author[0].title}>
                                                                        <p>
                                                                            {guidedBooks.author[0].title}
                                                                        </p>
                                                                    </NavLink>
                                                                </div>
                                                            </SwiperSlide>
                                                        )
                                                        : ""}

                                                    <div
                                                        className="more-btn-container d-flex justify-content-end mt--52">
                                                        <NavLink className="more-btn" to="/books?tab=guided">
                                                            المزيد
                                                        </NavLink>
                                                    </div>
                                                </Swiper>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {getArticleMustabasrs.length > 0 ?
                                    <Swiper
                                        slidesPerView={'auto'}
                                        spaceBetween={40}
                                        grabCursor={true}
                                        dir="rtl"
                                        loop={true}
                                        autoplay={{
                                            delay: 2000,
                                            disableOnInteraction: true,
                                            pauseOnMouseEnter: true,
                                        }}
                                        modules={[Navigation]}
                                        navigation={true}
                                        className={(getCount == 3) ? "mySwiper visible show " : "mySwiper visually-hidden fade"}>
                                        {getArticleMustabasrs.map(ArticleMustabasrs =>
                                            <SwiperSlide>
                                                <NavLink className="user-guide user-guide-2"
                                                         to={"/guided-contribute/" + ArticleMustabasrs.id}>
                                                    <div
                                                        className="header-user-image-container d-flex justify-content-center">
                                                        <img className="header-user-image"
                                                             src={ArticleMustabasrs.author.image}
                                                             alt={ArticleMustabasrs.author.title}
                                                             title={ArticleMustabasrs.author.title}/>
                                                    </div>
                                                    <div className="user-desc-container pt-phone-29">
                                                        <div className="user-name user-name-light">
                                                            {ArticleMustabasrs.author.title}
                                                        </div>
                                                        <div className="user-name">
                                                            {ArticleMustabasrs.title}
                                                        </div>
                                                        <div
                                                            className="d-flex justify-content-center mb-2  user-desc-container-2">
                                                            <div className="c-name">
                                                                {ArticleMustabasrs.author.country?.length > 0 ?
                                                                    <img
                                                                        src={"https://dashboard.aqaed.net/flags/" + (ArticleMustabasrs.author.country).toLowerCase() + ".svg"}
                                                                        style={{
                                                                            width: '1.4em',
                                                                            height: '1.4em',
                                                                        }}
                                                                        title={ArticleMustabasrs.author.country}
                                                                        alt={ArticleMustabasrs.author.country}
                                                                    />
                                                                    : ""}
                                                                {countries.getName(ArticleMustabasrs.author.country, 'ar')}
                                                            </div>
                                                            <div className="last-Religion" title="المذهب السابق">
                                                                {ArticleMustabasrs.author.previous_religion?.length > 0 ?
                                                                    <svg width="18" height="18" viewBox="0 0 18 18"
                                                                         fill="none"
                                                                         xmlns="http://www.w3.org/2000/svg">
                                                                        <g clipPath="url(#clip0_1718_10792)">
                                                                            <path
                                                                                d="M14.2032 14.339H5.30278C4.71444 13.2962 4.6156 12.0667 5.05333 10.9632C5.81111 9.0602 7.53377 7.5526 9.75063 7.00952C11.9675 7.5526 13.6902 9.0602 14.4479 10.9632C14.8904 12.0667 14.7915 13.2962 14.2032 14.339Z"
                                                                                stroke="#989898" strokewidth="0.9"
                                                                                strokeMiterlimit="10"
                                                                                strokeLinecap="round"
                                                                                strokeLinejoin="round"/>
                                                                            <path
                                                                                d="M5.99427 4.58516H3.56561C3.40558 4.29842 3.37734 3.96388 3.49971 3.6641C3.70681 3.14274 4.17748 2.73434 4.78465 2.58228C5.39181 2.72999 5.86248 3.14274 6.06958 3.6641C6.18254 3.96388 6.1543 4.30276 5.99427 4.58516Z"
                                                                                stroke="#989898" strokewidth="0.9"
                                                                                stroke-miterlimit="10"
                                                                                strokeLinecap="round"
                                                                                strokeLinejoin="round"/>
                                                                            <path
                                                                                d="M5.05389 10.9632C5.2704 10.4157 5.57163 9.90307 5.93404 9.43385V4.58521H3.62305V14.339H5.29864C4.7103 13.2962 4.61146 12.0667 5.05389 10.9632Z"
                                                                                stroke="#989898" strokewidth="0.9"
                                                                                stroke-miterlimit="10"
                                                                                strokeLinecap="round"
                                                                                strokeLinejoin="round"/>
                                                                            <path d="M9.75098 7.00958V5.82349"
                                                                                  stroke="#989898"
                                                                                  stroke-miterlimit="10"
                                                                                  strokeLinecap="round"
                                                                                  strokeLinejoin="round"/>
                                                                            <path d="M4.78027 2.58238V1.40063"
                                                                                  stroke="#989898"
                                                                                  strokewidth="0.9"
                                                                                  stroke-miterlimit="10"
                                                                                  strokeLinecap="round"
                                                                                  strokeLinejoin="round"/>
                                                                            <path
                                                                                d="M15.399 14.3391H2.54492V16.2855H15.399V14.3391Z"
                                                                                stroke="#989898" strokewidth="0.9"
                                                                                stroke-miterlimit="10"
                                                                                strokeLinecap="round"
                                                                                strokeLinejoin="round"/>
                                                                        </g>
                                                                        <defs>
                                                                            <clipPath id="clip0_1718_10792">
                                                                                <rect width="17" height="17"
                                                                                      fill="white"
                                                                                      transform="translate(0.381836 0.41333)"/>
                                                                            </clipPath>
                                                                        </defs>
                                                                    </svg>
                                                                    : ""}
                                                                {ArticleMustabasrs.author.previous_religion}
                                                            </div>
                                                        </div>
                                                        <div className="date-container-news text-center">
                                                            <svg className="mb-1 ms-1" width="19" height="18"
                                                                 viewBox="0 0 19 18"
                                                                 fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path
                                                                    d="M15.0417 3H3.95833C3.08388 3 2.375 3.67157 2.375 4.5V15C2.375 15.8284 3.08388 16.5 3.95833 16.5H15.0417C15.9161 16.5 16.625 15.8284 16.625 15V4.5C16.625 3.67157 15.9161 3 15.0417 3Z"
                                                                    stroke="#757575" strokeLinecap="round"
                                                                    strokeLinejoin="round"/>
                                                                <path d="M12.666 1.50012V4.50012" stroke="#757575"
                                                                      strokeLinecap="round"
                                                                      strokeLinejoin="round"/>
                                                                <path d="M6.33398 1.50012V4.50012" stroke="#757575"
                                                                      strokeLinecap="round" strokeLinejoin="round"/>
                                                                <path d="M2.375 7.5H16.625" stroke="#757575"
                                                                      strokeLinecap="round"
                                                                      strokeLinejoin="round"/>
                                                            </svg>
                                                            <div className="date">
                                                                <span
                                                                    className="number "> {ArticleMustabasrs.date_release ? ArticleMustabasrs.date_release.split("-")[2] : "02"} </span>
                                                                {getMonthName(ArticleMustabasrs.date_release ? ArticleMustabasrs.date_release.split("-")[1] : "02")}
                                                                <span
                                                                    className="number "> {ArticleMustabasrs.date_release ? ArticleMustabasrs.date_release.split("-")[0] : "1500"}  </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </NavLink>
                                            </SwiperSlide>
                                        )}
                                        <div className="more-btn-container d-flex justify-content-end mt--52">
                                            <NavLink className="more-btn" to="/guided-contribute">
                                                المزيد
                                            </NavLink>
                                        </div>
                                    </Swiper>
                                    : ""}
                                {getMoghabelat.length > 0 ?
                                    <Swiper
                                        slidesPerView={'auto'}
                                        spaceBetween={40}
                                        grabCursor={true}
                                        dir="rtl"
                                        loop={true}
                                        autoplay={{
                                            delay: 2000,
                                            disableOnInteraction: true,
                                            pauseOnMouseEnter: true,
                                        }}
                                        modules={[Navigation]}
                                        navigation={true}
                                        className={(getCount == 4) ? "mySwiper visible show user-slider-5" : "mySwiper visually-hidden fade"}>
                                        {getMoghabelat.map(Moghabelat =>
                                            <SwiperSlide>
                                                <NavLink className="user-guide user-guide-w300 phone-w-300"
                                                         to={"/guided-sayings/" + Moghabelat.id}>
                                                    <div
                                                        className="header-user-image-container d-flex justify-content-center">
                                                        <img className="header-user-image "
                                                             src={Moghabelat.author.image}
                                                             alt={Moghabelat.author.title}
                                                             title={Moghabelat.author.title}/>
                                                    </div>
                                                    <div className="user-desc-container back-transparent phone-w-300">
                                                        <img className="interviews phone-w-300" src={Moghabelat.image}
                                                             alt={Moghabelat.title}
                                                             title={Moghabelat.title}/>
                                                        <div className="overlay-play"></div>
                                                    </div>
                                                    <div className="container-to-top">
                                                        <div
                                                            className="d-flex mb-1 justify-content-start user-desc-container-2">
                                                            <div className="c-name">
                                                                {Moghabelat.author.country?.length > 0 ?
                                                                    <img
                                                                        src={"https://dashboard.aqaed.net/flags/" + (Moghabelat.author.country).toLowerCase() + ".svg"}
                                                                        style={{
                                                                            width: '1.4em',
                                                                            height: '1.4em',
                                                                        }}
                                                                        title={countries.getName(Moghabelat.author.country, 'ar')}
                                                                        alt={countries.getName(Moghabelat.author.country, 'ar')}
                                                                    />
                                                                    : ""}
                                                                {countries.getName(Moghabelat.author.country, 'ar')}
                                                            </div>
                                                            <div className="last-Religion" title="المذهب السابق">
                                                                {Moghabelat.author.previous_religion?.length > 0 ?
                                                                    <svg width="18" height="18" viewBox="0 0 18 18"
                                                                         fill="none"
                                                                         xmlns="http://www.w3.org/2000/svg">
                                                                        <g clipPath="url(#clip0_1718_10792)">
                                                                            <path
                                                                                d="M14.2032 14.339H5.30278C4.71444 13.2962 4.6156 12.0667 5.05333 10.9632C5.81111 9.0602 7.53377 7.5526 9.75063 7.00952C11.9675 7.5526 13.6902 9.0602 14.4479 10.9632C14.8904 12.0667 14.7915 13.2962 14.2032 14.339Z"
                                                                                stroke="#989898" stroke-width="0.9"
                                                                                stroke-miterlimit="10"
                                                                                strokeLinecap="round"
                                                                                strokeLinejoin="round"/>
                                                                            <path
                                                                                d="M5.99427 4.58516H3.56561C3.40558 4.29842 3.37734 3.96388 3.49971 3.6641C3.70681 3.14274 4.17748 2.73434 4.78465 2.58228C5.39181 2.72999 5.86248 3.14274 6.06958 3.6641C6.18254 3.96388 6.1543 4.30276 5.99427 4.58516Z"
                                                                                stroke="#989898" stroke-width="0.9"
                                                                                stroke-miterlimit="10"
                                                                                strokeLinecap="round"
                                                                                strokeLinejoin="round"/>
                                                                            <path
                                                                                d="M5.05389 10.9632C5.2704 10.4157 5.57163 9.90307 5.93404 9.43385V4.58521H3.62305V14.339H5.29864C4.7103 13.2962 4.61146 12.0667 5.05389 10.9632Z"
                                                                                stroke="#989898" stroke-width="0.9"
                                                                                stroke-miterlimit="10"
                                                                                strokeLinecap="round"
                                                                                strokeLinejoin="round"/>
                                                                            <path d="M9.75098 7.00958V5.82349"
                                                                                  stroke="#989898"
                                                                                  stroke-miterlimit="10"
                                                                                  strokeLinecap="round"
                                                                                  strokeLinejoin="round"/>
                                                                            <path d="M4.78027 2.58238V1.40063"
                                                                                  stroke="#989898"
                                                                                  stroke-width="0.9"
                                                                                  stroke-miterlimit="10"
                                                                                  strokeLinecap="round"
                                                                                  strokeLinejoin="round"/>
                                                                            <path
                                                                                d="M15.399 14.3391H2.54492V16.2855H15.399V14.3391Z"
                                                                                stroke="#989898" stroke-width="0.9"
                                                                                stroke-miterlimit="10"
                                                                                strokeLinecap="round"
                                                                                strokeLinejoin="round"/>
                                                                        </g>
                                                                        <defs>
                                                                            <clipPath id="clip0_1718_10792">
                                                                                <rect width="17" height="17"
                                                                                      fill="white"
                                                                                      transform="translate(0.381836 0.41333)"/>
                                                                            </clipPath>
                                                                        </defs>
                                                                    </svg>
                                                                    : ""}
                                                                {Moghabelat.author.previous_religion}
                                                            </div>
                                                        </div>
                                                        <div className="user-name">
                                                            {Moghabelat.title}
                                                        </div>

                                                    </div>

                                                </NavLink>
                                            </SwiperSlide>
                                        )}


                                        <div className="more-btn-container d-flex justify-content-end mt--52">
                                            <NavLink className="more-btn" to="/guided-sayings">
                                                المزيد
                                            </NavLink>
                                        </div>
                                    </Swiper>
                                    : ""
                                }
                                <div
                                    className={(getCount == 5) ? "btn-container h-300 my-5 py-5 text-center visible show" : "btn-container h-300 my-5 py-5 text-center visually-hidden fade"}>
                                    <p className="mb-5">
                                        هذه النافذة خاصّة بالمستبصرين الأعزّاء؛ لكي يعلنوا فيها عن اعتناقهم لمذهب أهل
                                        البيت(عليهم‌ السلام).
                                    </p>
                                    <div className=" d-flex justify-content-center ">
                                        <button onClick={() => {
                                            if (emailIsLogin.length > 0) {
                                                setShowLogin1(true)
                                            } else {
                                                setShowLogin(true)
                                            }
                                        }} className="border-0 fw-light more-btn">
                                            إرسال المعلومات
                                        </button>
                                    </div>
                                    {showLogin1 ?
                                        <Forms show={showLogin1} page={3} close={() => setShowLogin1(false)}/>
                                        : ""}
                                </div>
                            </> :

                            <div
                                className="btn-container h-300 my-5 py-5 text-center visible show">
                                <div className=" d-flex justify-content-center ">
                                    <button className="border-0 fw-light more-btn more-btn-not-fill">
                                        <img className="image-loading image-loading-2" src={imageLoading}
                                             alt="loading"
                                             title="loading"/> تحميل...
                                    </button>
                                </div>

                            </div>
                        }
                    </div>
                </div>
            </div>

            <div className="aq-container left-line-3"></div>
            <div className="aq-container left-line-4"></div>
        </div>
    );
};

export default Guided;
